<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
            <template v-if="!isViewReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="!isAuthenticated">
                <LoginCard :nextRoute="this.$router.currentRoute.fullPath"/>
            </template>
            <template v-if="isViewReady">
                <template v-if="isAuthenticated && domain">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'account-edit-dns', params: { accountId: domain.account_id, domain: domain.name } }">Manage DNS</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="isAuthenticated && !domain">
                    <v-row style="height: 100%" align="center" justify="center">
                        <v-alert dense type="error" border="left">
                            <template #prepend>
                                <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                            </template>
                            <span class="ml-2 text-body-1">This domain is not in your account.</span>
                        </v-alert>
                    </v-row>
                    <p class="mt-6">
                        <router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link>
                    </p>
                </template>
            </template>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import LoginCard from '@/components/LoginCard.vue';

export default {
    components: {
        LoginCard,
    },
    data: () => ({
        isDomainReady: false,
        domain: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isViewReady() {
            return this.isAuthenticatedReady && this.isDomainReady;
        },
        isRedirectReady() {
            return this.isAuthenticated && this.domain;
        },
    },
    watch: {
        isAuthenticated(newValue, oldValue) {
            if (newValue && !oldValue) {
                if (this.$route.params.domain) {
                    this.loadDomain();
                } else {
                    this.redirectToSearch();
                }
            }
        },
        isRedirectReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.redirectAuthenticatedUser();
            }
        },
    },
    methods: {
        async loadDomain() {
            try {
                this.isDomainReady = false;
                this.domain = null;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.user(this.session.userId).self.search({ q: this.$route.params.domain, type: 'domain', match: 'eq' });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.domain_list) && response.domain_list.length > 0) {
                    this.domain = response.domain_list[0];
                }
            } catch (err) {
                console.error('failed to load domain', err);
            } finally {
                this.$store.commit('loading', { loadDomain: false });
                this.isDomainReady = true;
            }
        },
        redirectAuthenticatedUser() {
            this.$router.replace({ name: 'account-edit-dns', params: { accountId: this.domain.account_id, domain: this.domain.name } });
        },
        /**
         * Call this when user is authenticated but domain was not specified in URL
         */
        redirectToSearch() {
            this.$router.replace({ name: 'search' });
        },
    },
    mounted() {
        if (this.isAuthenticated) {
            if (this.$route.params.domain) {
                this.loadDomain();
            } else {
                this.redirectToSearch();
            }
        }
    },
};
</script>
